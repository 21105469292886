import { useEffect, useState } from 'react';
import { Organization } from 'src/types/models/Organization';
import { Alert, Button, Paper, Progress, Table } from '@mantine/core';
import useTranslation from 'src/i18next.config';
import { useOrganizationService } from 'src/services/organization';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from 'src/types/enum/routes.enum';

function OrganizationsList() {
    const organizationService = useOrganizationService();
    const { t } = useTranslation();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [errorMessage] = useState<string | null>();
    const organizationsArray = organizations ? organizations : [];
    const navigate = useNavigate();

    useEffect(function () {
        if (organizations.length < 1) {
            organizationService.getOrganizations({
                error: error => {
                    console.error(error);
                },
                success: res => {
                    setOrganizations(res);
                },
            });
        }
    }, []);

    function cleanDisplayNumber(value: number): number {
        if (value % 1) {
            return parseFloat(value.toFixed(2));
        } else {
            return value;
        }
    }
    function displayElapsedTime(organization: Organization) {
        if (
            organization.enabledExecutionTime &&
            !isNaN(organization.maxExecutionTime) &&
            organization.maxExecutionTime > 0
        ) {
            return (
                <>
                    <span>
                        {cleanDisplayNumber(organization.usedExecutionTime / 60)}/
                        {cleanDisplayNumber(organization.maxExecutionTime / 60)}
                    </span>
                    <Progress value={(organization.usedExecutionTime / organization.maxExecutionTime) * 100} />
                </>
            );
        }
    }

    function displayNumberOfTimes(organization: Organization) {
        if (organization.enabledMaxExecutions && !isNaN(organization.maxExecutions) && organization.maxExecutions > 0) {
            return (
                <>
                    <span>
                        {organization.usedExecutions}/{organization.maxExecutions}
                    </span>
                    <Progress value={(organization.usedExecutions / organization.maxExecutions) * 100} />
                </>
            );
        }
    }

    const rows = organizationsArray.map((organization: Organization) => {
        return (
            <tr key={organization._id}>
                <td>{organization.name}</td>
                <td>{organization._id}</td>
                <td>{organization.resetDay}</td>
                <td>{displayElapsedTime(organization)}</td>
                <td>{displayNumberOfTimes(organization)}</td>
                <td>
                    <Button
                        color="cyan.8"
                        variant="outline"
                        onClick={() => {
                            navigate(`/admin/organizations/${organization._id}/edit`);
                        }}
                    >
                        {t('organization.editOrganizationBtn')}
                    </Button>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Button
                color="cyan.8"
                type="submit"
                style={{ marginBottom: 20 }}
                onClick={() => {
                    navigate(ERoutes.ADMIN_ADD_ORGANIZATION);
                }}
            >
                {t('organization.addOrganizationBtn')}
            </Button>
            <Paper shadow="md" style={{ width: 'fit-content', margin: 'auto' }}>
                <Table>
                    <thead className="table-head">
                        <tr>
                            <th style={{ minWidth: '120px' }}>{t('Name')}</th>
                            <th>{'ID'}</th>
                            <th style={{ minWidth: '65px' }}>{t('resetDay')}</th>
                            <th style={{ minWidth: '250px' }}>{t('usedExecutionTime')}</th>
                            <th style={{ minWidth: '200px' }}>{t('usedExecutions')}</th>
                            <th>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </Paper>
            {errorMessage && (
                <Alert color="red" radius="lg" variant="filled" style={{ marginTop: 10 }}>
                    {errorMessage}
                </Alert>
            )}
        </>
    );
}

export default OrganizationsList;
