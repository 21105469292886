import { Button, Checkbox, Grid, Group, MultiSelect, NumberInput, Paper, Select, Stack, TextInput, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Organization } from 'src/types/models/Organization';
import ProgressCard from '../../core/ProgressCard';
import useTranslation from 'src/i18next.config';
import { useAgentService } from 'src/services/agent';
import { Agent } from 'src/types/models/Agent';

type OrganizationFormProps = {
    initialOrganization?: Organization;
    requestButtonLable: string;
    title: string;
    onSave: (payload: Organization) => void;
};
export function OrganizationForm({ initialOrganization, requestButtonLable, title, onSave }: OrganizationFormProps) {
    const { t } = useTranslation();

    const [formCompleted, setFormCompleted] = useState(false);
    const agentService = useAgentService();
    const [agents, setAgents] = useState<Agent[]>();

    useEffect(function () {
        if (!agents) {
            agentService.getAgents({
                error: error => {
                    showNotification({
                        title: t('agent.noAgentFound'),
                        message: error ? error.message : t('agent.addAgentErrorMessage'),
                        color: 'red',
                    });
                },
                success: res => {
                    setAgents(res);
                },
            });
        }
    }, []);

    const formik = useFormik<Organization>({
        initialValues: {
            _id: '',
            name: '',
            maxExecutions: 0,
            enabledExecutionTime: false,
            maxExecutionTime: 0,
            enabledMaxExecutions: false,
            maxFilesSizePerExecution: 0,
            enabledTotalFilesSizeAllowedPerUser: false,
            maxNumberOfUser: 0,
            enabledMaxNumberOfUsers: false,
            maxTimeout: 0,
            enabledMaxTimeout: false,
            maxConcurrentExecutions: 0,
            enabledMaxConcurrentExecutions: false,
            totalFilesSizeAllowedPerUser: 0,
            enabledMaxFilesSizePerExecution: false,
            usedExecutions: 0,
            usedExecutionTime: 0,
            resetDay: Math.min(new Date().getDay(), 28),
            disabledAgents: [],
        },
        onSubmit: saveClicked,
    });

    useEffect(() => {
        if (initialOrganization) {
            formik.setValues(initialOrganization);
        }
    }, []);

    useEffect(() => {
        if (isFormValid()) {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
    }, [formik]);

    function isFormValid() {
        const {
            name,
            maxExecutions,
            enabledExecutionTime,
            maxExecutionTime,
            enabledMaxExecutions,
            maxFilesSizePerExecution,
            enabledMaxFilesSizePerExecution,
            maxNumberOfUser,
            enabledMaxNumberOfUsers,
            maxTimeout,
            enabledMaxTimeout,
            maxConcurrentExecutions,
            enabledMaxConcurrentExecutions,
            totalFilesSizeAllowedPerUser,
            enabledTotalFilesSizeAllowedPerUser,
            usedExecutions,
            usedExecutionTime,
            disabledAgents,
        } = formik.values;

        if (
            name &&
            maxExecutions != null &&
            maxExecutionTime != null &&
            maxFilesSizePerExecution != null &&
            maxNumberOfUser != null &&
            maxTimeout != null &&
            maxConcurrentExecutions != null &&
            totalFilesSizeAllowedPerUser != null &&
            usedExecutions != null &&
            usedExecutionTime != null &&
            enabledExecutionTime != null &&
            enabledMaxExecutions != null &&
            enabledMaxFilesSizePerExecution != null &&
            enabledMaxNumberOfUsers != null &&
            enabledMaxTimeout != null &&
            enabledTotalFilesSizeAllowedPerUser != null &&
            disabledAgents != null
        ) {
            return true;
        } else {
            return false;
        }
    }

    function displayElapsedTimeUsage() {
        if (
            formik.values.enabledExecutionTime &&
            !isNaN(formik.values.maxExecutionTime) &&
            formik.values.maxExecutionTime > 0
        ) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={formik.values.usedExecutionTime}
                        displayName="Used time"
                        max={formik.values.maxExecutionTime}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    function displayNumberOfUsage() {
        if (
            formik.values.enabledMaxExecutions &&
            !isNaN(formik.values.maxExecutions) &&
            formik.values.maxExecutions > 0
        ) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={formik.values.usedExecutions}
                        displayName="Used number of executions"
                        max={formik.values.maxExecutions}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    async function saveClicked(values: Organization) {
        if (!formCompleted) {
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
            });
        } else {
            onSave(values);
        }
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            style={{ minWidth: '500px', width: '50%', margin: 'auto' }}
        >
            <Stack spacing="xs">
                <Title order={2}>{title}</Title>
                <TextInput
                    id="name"
                    type="text"
                    label={t('name')}
                    required={true}
                    placeholder={t('name').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={!!formik.touched.name && !!formik.errors.name && formik.errors.name}
                />
                <MultiSelect
                    style={{ flex: '1 0 auto' }}
                    label={t('agent.disableMessage')}
                    placeholder={t('agent.disablePlaceholder') ?? ""}
                    nothingFound={t('agents.noAgentFound')}
                    id="disabledAgents"
                    searchable
                    withinPortal={true}
                    value={formik.values.disabledAgents}
                    onChange={value => formik.setFieldValue('disabledAgents', value)}
                    data={agents?.map(agent => ({ value: agent._id as string, label: agent.reference as string })) || []}
                ></MultiSelect>
                <NumberInput
                    style={{ flex: '1 0 auto' }}
                    defaultValue={formik.values.resetDay}
                    error={!!formik.touched.resetDay && !!formik.errors.resetDay && formik.errors.resetDay}
                    id="resetDay"
                    label={t('resetDay')}
                    max={28}
                    min={1}
                    onChange={value => formik.setFieldValue('resetDay', value)}
                    value={formik.values.resetDay}
                    placeholder={t('resetDay').toString()}
                    required={true}
                    type="number"
                    withAsterisk
                />
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxExecutions"
                        label={t('maxExecutions')}
                        type="text"
                        required={true}
                        placeholder={t('maxExecutions').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxExecutions}
                        error={
                            !!formik.touched.maxExecutions &&
                            !!formik.errors.maxExecutions &&
                            formik.errors.maxExecutions
                        }
                    />

                    <Checkbox
                        checked={formik.values.enabledMaxExecutions}
                        onChange={event => formik.setFieldValue('enabledMaxExecutions', event.currentTarget.checked)}
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxExecutionTime"
                        type="text"
                        label={t('maxExecutionTime')}
                        required={true}
                        placeholder={t('maxExecutionTime').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxExecutionTime}
                        error={
                            !!formik.touched.maxExecutionTime &&
                            !!formik.errors.maxExecutionTime &&
                            formik.errors.maxExecutionTime
                        }
                    />
                    <Checkbox
                        checked={formik.values.enabledExecutionTime}
                        onChange={event => formik.setFieldValue('enabledExecutionTime', event.currentTarget.checked)}
                        style={{ paddingTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxFilesSizePerExecution"
                        type="text"
                        label={t('maxFilesSizePerExecution')}
                        required={true}
                        placeholder={t('maxFilesSizePerExecution').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxFilesSizePerExecution}
                        error={
                            !!formik.touched.maxFilesSizePerExecution &&
                            !!formik.errors.maxFilesSizePerExecution &&
                            formik.errors.maxFilesSizePerExecution
                        }
                    />
                    <Checkbox
                        checked={formik.values.enabledMaxFilesSizePerExecution}
                        onChange={event =>
                            formik.setFieldValue('enabledMaxFilesSizePerExecution', event.currentTarget.checked)
                        }
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>

                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxNumberOfUser"
                        type="text"
                        label={t('maxNumberOfUser')}
                        required={true}
                        placeholder={t('maxNumberOfUser').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxNumberOfUser}
                        error={
                            !!formik.touched.maxNumberOfUser &&
                            !!formik.errors.maxNumberOfUser &&
                            formik.errors.maxNumberOfUser
                        }
                    />
                    <Checkbox
                        checked={formik.values.enabledMaxNumberOfUsers}
                        onChange={event => formik.setFieldValue('enabledMaxNumberOfUsers', event.currentTarget.checked)}
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxTimeout"
                        type="text"
                        label={t('maxTimeout')}
                        required={true}
                        placeholder={t('maxTimeout').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxTimeout}
                        error={!!formik.touched.maxTimeout && !!formik.errors.maxTimeout && formik.errors.maxTimeout}
                    />
                    <Checkbox
                        checked={formik.values.enabledMaxTimeout}
                        onChange={event => formik.setFieldValue('enabledMaxTimeout', event.currentTarget.checked)}
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="maxConcurrentExecutions"
                        type="text"
                        label={t('maxConcurrentExecutions')}
                        required={true}
                        placeholder={t('maxConcurrentExecutions').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.maxConcurrentExecutions}
                        error={!!formik.touched.maxConcurrentExecutions && !!formik.errors.maxConcurrentExecutions && formik.errors.maxConcurrentExecutions}
                    />
                    <Checkbox
                        checked={formik.values.enabledMaxConcurrentExecutions}
                        onChange={event => formik.setFieldValue('enabledMaxConcurrentExecutions', event.currentTarget.checked)}
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <Group>
                    <TextInput
                        style={{ flex: '1 0 auto' }}
                        id="totalFilesSizeAllowedPerUser"
                        type="text"
                        label={t('totalFilesSizeAllowedPerUser')}
                        required={true}
                        placeholder={t('totalFilesSizeAllowedPerUser').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.totalFilesSizeAllowedPerUser}
                        error={
                            !!formik.touched.totalFilesSizeAllowedPerUser &&
                            !!formik.errors.totalFilesSizeAllowedPerUser &&
                            formik.errors.totalFilesSizeAllowedPerUser
                        }
                    />
                    <Checkbox
                        checked={formik.values.enabledTotalFilesSizeAllowedPerUser}
                        onChange={event =>
                            formik.setFieldValue('enabledTotalFilesSizeAllowedPerUser', event.currentTarget.checked)
                        }
                        style={{ marginTop: 32 }}
                        label={t('enableLimit')}
                        color="green"
                    />
                </Group>
                <TextInput
                    style={{ flex: '1 0 auto' }}
                    id="usedExecutions"
                    type="text"
                    label={t('usedExecutions')}
                    required={true}
                    placeholder={t('usedExecutions').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.usedExecutions}
                    error={
                        !!formik.touched.usedExecutions &&
                        !!formik.errors.usedExecutions &&
                        formik.errors.usedExecutions
                    }
                />
                <TextInput
                    style={{ flex: '1 0 auto' }}
                    id="usedExecutionTime"
                    type="text"
                    label={t('usedExecutionTime')}
                    required={true}
                    placeholder={t('usedExecutionTime').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.usedExecutionTime} // For the display: convert from seconds to minutes
                    error={
                        !!formik.touched.usedExecutionTime &&
                        !!formik.errors.usedExecutionTime &&
                        formik.errors.usedExecutionTime
                    }
                />

                <Grid>
                    {displayElapsedTimeUsage()}
                    {displayNumberOfUsage()}
                </Grid>
                <div style={{ marginTop: 10, display: 'flex', columnGap: '1rem' }}>
                    <Button color="cyan.8" type="submit">
                        {t(`${requestButtonLable}`)}
                    </Button>
                </div>
            </Stack>
        </Paper>
    );
}
